import { FC } from 'react';
import {
  Area,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  Tooltip,
} from 'recharts';
import { AreaChartProps } from './types';

const CustomAreaChart: FC<AreaChartProps> = ({ chartConfig, areaData, dataDisplay }) => {
  return (
    <ResponsiveContainer width={chartConfig.width} height={chartConfig.height}>
      <AreaChart data={areaData}>
        <CartesianGrid
          strokeDasharray={chartConfig.strokeDashArray}
          vertical={chartConfig.verticalLines}
          horizontal={chartConfig.horizontalLines}
        />
        <XAxis
          dataKey={chartConfig.xAxisKey}
          axisLine={chartConfig.axisLine}
          tickLine={chartConfig.tickLine}
        />
        <YAxis
          dataKey={chartConfig.yAxisKey}
          axisLine={chartConfig.axisLine}
          tickLine={chartConfig.tickLine}
        />
        <Tooltip />
        <YAxis />
        {dataDisplay.map(({ key, fillColor, strokeColor }) => (
          <Area key={key} dataKey={key} fill={fillColor} stroke={strokeColor} type="monotone" />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
