import { screenSm } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  background-color: ${colors.body.bg};
  padding: 10px;
  ${screenSm} {
    flex-direction: column;
    width: 202px;
    padding: 15px;
  }
`;

export const CounterCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: ${colors.fonts.darkGray};
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const Title = styled.h2`
  font-size: 18px;
  text-align: center;
`;
