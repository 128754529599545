import styled from 'styled-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import { IChart, ICounters, IEntitiesTableData } from 'contexts/analytics/types';
import AnalyticsCounters from './AnalyticsCounters';
import './custom.scss';
import { screenSm } from 'constants/styles/breakpoints';
import EntitiesTable from './AnalyticsEntitiesTable';
import AnalyticsActivitiesChart from './AnalyticsActivitiesChart';
import MetricTabs from './MetricTabs';
import PanelHeader from './ChartPanelHader/PanelHeader';
import {
  ACTIVITIES_TYPE,
  LABS_TYPE,
  QUIZZES_TYPE,
  USERS_TYPE,
  VIDEOS_OVERALL_TYPE,
  VIDEOS_TYPE,
} from 'constants/analytics';

const PanelContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  ${screenSm} {
    padding: 13px 30px 30px;
    margin-top: 10px;
  }
`;

const ChartPanelContainer = styled.div<{ maxHeight?: string }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || '320px'};

  ${screenSm} {
    flex-direction: row;
  }
`;
const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  opacity: 1;
  transition: opacity 0.4s;
  height: 100%;
  background-color: #f2f2f7;
  padding-top: 10px;

  ${screenSm} {
    background-color: #fff;
    padding: 0 30px 30px 30px;
  }
`;

interface IActiveUsersStatsParams {
  actions: {
    onUpdateChart: (tab: string) => void;
  };
  tabSelected: string;
  counters: ICounters;
  activities: IChart;
  entitiesTable: IEntitiesTableData;
}

const ActiveUsersStatsPanel = (data: IActiveUsersStatsParams): JSX.Element => {
  const { currentChart, filters, fetching } = useContext(AnalyticsContext);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [headerDisplay, setHeaderDisplay] = useState([]);

  const changeTab = (tab: string) => {
    data.actions.onUpdateChart(tab);
  };

  const toggleCheckbox = (key: string) => {
    setDataDisplay((prevState) => {
      const newState = [...prevState];

      const index = newState.findIndex((o) => o.key === key);

      newState[index].checked = !newState[index].checked;

      return newState;
    });
  };

  const togglePanelOption = (key: string) => {
    setHeaderDisplay((prevState) => {
      const newState = [...prevState].map((item) => {
        return { ...item, checked: item.key === key };
      });
      return newState;
    });
    const videoTab = key === 'time_watched' ? 'videos' : 'videos_overall';
    changeTab(videoTab);
  };

  const timeLabel = useMemo(() => {
    const { date_from, date_to, period } = filters;
    return {
      lapse: date_from && date_to ? `${date_from} to ${date_to}` : 'month',
      period: period ? period : 'day',
    };
  }, [fetching]);

  useEffect(() => {
    setDataDisplay(() => {
      switch (currentChart.toUpperCase()) {
        case USERS_TYPE.VALUE: {
          return [
            {
              key: 'total',
              tooltipLabel: null,
              counterTooltipLabel:
                'Current number of users in the account, including both licensed and unlicensed users',
              checked: false,
              fixed: false,
              disabled: true,
              counterLabel: 'total users',
            },
            {
              key: 'licensed',
              tooltipLabel: 'Current number of users in the account with a plan license assigned',
              counterTooltipLabel:
                'Current number of users in the account with a plan license assigned',
              checked: true,
              fixed: true,
              disabled: false,
              counterLabel: 'licensed users',
              label: 'Licensed users',
            },
            {
              key: 'percentage',
              tooltipLabel: null,
              counterTooltipLabel:
                'Percentage of users with a plan license assigned currently who have performed at least one activity within the selected timeframe',
              checked: false,
              fixed: false,
              disabled: true,
              counterLabel: 'licensed users with activity',
            },
          ];
        }

        case ACTIVITIES_TYPE.VALUE: {
          return [
            {
              key: 'overall',
              label: 'Total activities',
              tooltipLabel: `Initiated activities within the account per ${timeLabel.period}`,
              counterTooltipLabel:
                'Total number of initiated activities within the account, including Hands-on labs, videos and quizzes',
              checked: true,
              fixed: true,
            },
            {
              key: 'labs',
              label: 'Labs',
              tooltipLabel:
                'Initiated hands-on labs within the account including Skill Dive and standard labs',
              counterTooltipLabel:
                'Percentage of initiated hands-on labs including Skill Dive and standard labs',
              checked: false,
              fixed: false,
              fixedFor: ['BarChart'],
              percentageKey: 'overall',
            },
            {
              key: 'videos',
              label: 'Videos',
              tooltipLabel: 'Initiated videos within the account',
              counterTooltipLabel: 'Percentage of initiated videos',
              checked: false,
              fixed: false,
              fixedFor: ['BarChart'],
              percentageKey: 'overall',
            },
            {
              key: 'quizzes',
              label: 'Quizzes',
              tooltipLabel: 'Initiated quizzes within the account',
              counterTooltipLabel: 'Percentage of initiated quizzes',
              checked: false,
              fixed: false,
              fixedFor: ['BarChart'],
              percentageKey: 'overall',
            },
          ];
        }
        case VIDEOS_TYPE.VALUE: {
          return [
            {
              key: 'started',
              label: 'Started videos',
              tooltipLabel: `Number of initiated videos within the account per ${timeLabel.period}`,
              counterTooltipLabel: 'Total number of times videos were initiated within the account',
              checked: true,
              fixed: true,
            },
            {
              key: 'finished',
              label: 'Finished videos',
              tooltipLabel: `Number of finished videos within the account per ${timeLabel.period}`,
              counterTooltipLabel: 'Total number of times videos were finished within the account',
              checked: false,
              fixed: false,
            },
          ];
        }
        case VIDEOS_OVERALL_TYPE.VALUE: {
          return [
            {
              key: 'watched',
              label: 'Time watched',
              counterLabel: 'Time watched',
              counterTooltipLabel: 'Total watched time videos within the account',
              checked: true,
              fixed: true,
            },
          ];
        }
        case QUIZZES_TYPE.VALUE: {
          return [
            {
              key: 'started',
              tooltipLabel: `Number of initiated quizzes within the account per ${timeLabel.period}`,
              counterTooltipLabel:
                'Total number of times quizzes were initiated within the account',
              checked: true,
              fixed: true,
            },
            {
              key: 'finished',
              tooltipLabel: `Number of finished quizzes within the account per ${timeLabel.period}`,
              counterTooltipLabel: 'Total number of times quizzes were finished within the account',
              checked: false,
              fixed: false,
            },
            {
              key: 'passed',
              tooltipLabel: `Number of passed quizzes within the account per ${timeLabel.period}`,
              counterTooltipLabel:
                'Total number of passed quizzes were finished within the account',
              checked: false,
              fixed: false,
            },
          ];
        }
        case LABS_TYPE.VALUE: {
          return [
            { key: 'started', checked: true, disabled: true },
            { key: 'finished', checked: false, disabled: false },
          ];
        }
      }
    });
    setHeaderDisplay(() => {
      switch (currentChart.toUpperCase()) {
        case USERS_TYPE.VALUE:
          return [
            {
              key: 'users_with_acvitity',
              checked: 'users' === currentChart,
              text: `Number of <b>users with a plan license assigned</b> who have performed at least one individual activity per day within ${timeLabel.lapse}`,
              label: 'Users with activity',
            },
          ];
        case VIDEOS_TYPE.VALUE:
        case VIDEOS_OVERALL_TYPE.VALUE:
          return [
            {
              key: 'videos_watched_time_series',
              checked: 'videos_overall' === currentChart,
              text: `Video time watched within `,
              label: 'Time watched',
            },
            {
              key: 'time_watched',
              checked: 'videos' === currentChart,
              text: `Number of videos initiated within ${timeLabel.period}`,
              label: 'Overall',
            },
          ];
        case QUIZZES_TYPE.VALUE:
          return [
            {
              key: 'quizzes_started_time_series',
              checked: 'quizzes' === currentChart,
              text: `Number of quizzes initiated within ${timeLabel.period}`,
              label: '',
            },
          ];
        case ACTIVITIES_TYPE.VALUE:
          return [
            {
              key: 'overall_activities_time_series',
              checked: 'activities' === currentChart,
              text: `Number of total overall activities initiated within ${timeLabel.period}`,
              label: '',
            },
          ];
        default:
          return [];
      }
    });
  }, [currentChart, fetching]);

  return (
    <>
      <MetricTabs isLoading={fetching} counters={data.counters} changeTab={changeTab} />
      <PanelContainer>
        <PanelHeader togglePanelOption={togglePanelOption} dataDisplay={headerDisplay} />
        <ChartPanelContainer
          maxHeight={currentChart.toUpperCase() === ACTIVITIES_TYPE.VALUE ? '420px' : '320px'}
        >
          <AnalyticsCounters
            displayCounters={(() => {
              return dataDisplay.length
                ? dataDisplay.map(
                    ({ key, counterLabel, counterTooltipLabel, disabled, percentageKey }) => {
                      return { key, counterLabel, counterTooltipLabel, disabled, percentageKey };
                    }
                  )
                : [];
            })()}
          />
          <AnalyticsActivitiesChart toggleCheckbox={toggleCheckbox} dataDisplay={dataDisplay} />
        </ChartPanelContainer>
      </PanelContainer>
      <ContentTable>
        <EntitiesTable
          section={'analytics'}
          hideHeader={currentChart.toUpperCase() === USERS_TYPE.VALUE}
        />
      </ContentTable>
    </>
  );
};

export default ActiveUsersStatsPanel;
