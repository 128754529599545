import { LABS_COUNTER, QUIZZES_COUNTER, VIDEOS_COUNTER } from 'constants/analytics';
import { colors } from 'constants/styles/colors';
import { DashboardActivitiesStats } from './types';

export const DASHBOARD_ACTIVITIES_STATS: DashboardActivitiesStats[] = [
  {
    key: 'VIDEOS_STARTED',
    label: 'Videos started',
    actions: VIDEOS_COUNTER.STARTED.ACTIONS,
  },
  {
    key: 'VIDEO_WATCHED_TIME',
    label: 'Video watched time',
    actions: undefined,
    external_actions: VIDEOS_COUNTER.WATCHED.EXTERNAL_ACTION,
  },
  {
    key: 'QUIZZES_STARTED',
    label: 'Quizzes started',
    actions: QUIZZES_COUNTER.STARTED.ACTIONS,
  },
  {
    key: 'TOTAL_LABS',
    label: 'Total labs',
    actions: LABS_COUNTER.OVERALL.ACTIONS,
  },
];

export const areaChartConfig = {
  width: '100%',
  height: 400,
  strokeDashArray: '1 2',
  verticalLines: false,
  axisLine: false,
  tickLine: false,
  xAxisKey: 'date',
  yAxisKey: 'count',
};

export const areaDataDisplay = [
  {
    key: 'count',
    fillColor: colors.dataVisualization.purple.lighter,
    strokeColor: colors.dataVisualization.purple.base,
  },
];
