import { DAY_PERIOD } from 'constants/filters';
import { USERS_ENTITY, USERS_TYPE } from 'constants/analytics';
import { WEEK_PERIOD } from 'constants/filters';
import { AnalyticsDataCtx } from './types';

export const initalChartValues = {
  loading: false,
  count: null,
  finished: null,
  passed: null,
  error: false,
  items: null,
};

const initialEntitiesTableValues = {
  items: null,
  pageSize: 9,
  page: 1,
  loading: false,
  count: 0,
  error: false,
  currentEntity: USERS_ENTITY.VALUE,
};

const initialCounterValue = {
  finished: null,
  passed: null,
  overall: null,
  started: null,
  watched: null,
  error: false,
  loading: false,
};

const initialActivitiesCounterValue = {
  ...initialCounterValue,
  labs: null,
  quizzes: null,
  videos: null,
};
const initialActiveUsersCounterValue = {
  total: null,
  licensed: null,
  percentage: null,
};

export const analyticsInitialValues: AnalyticsDataCtx = {
  error: null,
  fetching: false,
  filters: {
    date_to: null,
    date_from: null,
    team_ids: null,
    period: DAY_PERIOD.VALUE,
  },
  tableOrdering: null,
  activities: initialActivitiesCounterValue,
  activeUsers: initalChartValues,
  entitiesTable: initialEntitiesTableValues,
  currentChart: USERS_TYPE.VALUE.toLowerCase(),
  counters: {
    users: initialActiveUsersCounterValue,
    activities: initialCounterValue,
    videos: initialCounterValue,
    videos_overall: initialCounterValue,
    slides: initialCounterValue,
    labs: initialCounterValue,
    quizzes: initialCounterValue,
  },
};
