import styled from 'styled-components';
import Icon from 'components/icons';
import { screenMd } from 'constants/styles/breakpoints';
import { ASIDE_DESKTOP_WIDTH, HEADER_HEIGHT } from 'constants/settings';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const TitleContainer = styled.div<{ fixedY?: string; showingNotification?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: var(--black-dark);

  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;

  margin-top: 20px;

  position: absolute;
  top: ${({ fixedY }) => fixedY ?? '0'};

  ${({ showingNotification, fixedY }) =>
    showingNotification &&
    `
      ${screenMd} {
        top: calc( ${fixedY} + 40px);
      }
    `}
`;

export const SectionCard = styled.section<{
  rounded?: boolean;
  shadow?: boolean;
  padding?: string;
  dashboard?: boolean;
  fixedY?: string;
  showingNotification?: boolean;
}>`
  background: #fff;
  padding: 30px;

  ${({ padding }) =>
    padding &&
    `
		padding: ${padding};
	`}

  ${({ rounded }) =>
    rounded &&
    `
		border-radius: 10px;
	`}

	${({ shadow }) =>
    shadow &&
    `
		box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	`}

  ${({ dashboard }) =>
    dashboard &&
    `
    width: 100%;
    border: none;
	`}

  transition-property: top;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  ${({ fixedY }) =>
    fixedY &&
    `
		top: ${fixedY};
    position: fixed;
    z-index: 2;
    width: calc(100% - 40px);
    left: unset;

    ${screenMd} {
      width: calc(100% - ${ASIDE_DESKTOP_WIDTH + 'px'} - 40px);
      left: calc(${ASIDE_DESKTOP_WIDTH + 'px'} + 20px);
    }
	`}

  ${({ showingNotification, fixedY }) =>
    showingNotification &&
    `
    ${screenMd} {
      top: calc( ${fixedY} + 40px);
    }
  `}
`;

export const BannerContainer = styled.div<{ impersonated?: boolean }>`
  position: fixed;
  background: var(--secondary-promo-purple-gradient);
  width: calc(100vw - 140px);
  height: 40px;
  top: ${({ impersonated }) => (impersonated ? HEADER_HEIGHT + 66 + 'px' : HEADER_HEIGHT + 'px')};
  left: 140px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  display: none;
  z-index: 2;
  ${screenMd} {
    display: flex;
  }
`;

export const BannerText = styled.span`
  color: var(--white);
  font-family: Circular-Std;
  font-size: 16px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: var(--white);
  font-size: 16px;
  cursor: pointer;
`;

export const CloseIcon = styled(Icon)`
  path {
    fill: var(--white);
  }
`;
