import { Table, Top, Cell, Row, Pagination } from 'components/Table';
import { isMobile } from 'react-device-detect';
import {
  ACTIVITIES_TYPE,
  ENTITIES_COLUMNS,
  QUIZZES_TYPE,
  STATISTICS_ENTITIES,
  USERS_TYPE,
  VIDEOS_OVERALL_TYPE,
  VIDEOS_TYPE,
} from 'constants/analytics';
import { useContext, useMemo, useState } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import {
  EmptyTable,
  EntitiesWrapper,
  EntityButton,
  OptionsSection,
  Separator,
  TableContainer,
  TableLabel,
  TableWrapper,
} from './styles';
import './statics.scss';
import { Spinner } from 'components/loadings';
import Icon from 'components/icons';
import RowMobile from './RowMobile';
import TableCellContent from './TableCellContent';
import { Tooltip } from 'antd';
import { IColumnMetadata } from 'contexts/analytics/types';

const iconStyles = {
  marginLeft: '5px',
} as React.CSSProperties;

type Props = {
  section?: string;
  hideHeader?: boolean;
};

const EnititesTable = ({ section, hideHeader }: Props): JSX.Element => {
  const { actions, currentChart, entitiesTable, tableOrdering } = useContext(AnalyticsContext);
  const [order, setOrder] = useState(0);
  const activeUsersColumnsTableConfig = useMemo(() => {
    if (isMobile) return '90% 1fr';
    switch (currentChart) {
      case VIDEOS_TYPE.VALUE.toLowerCase():
      case VIDEOS_OVERALL_TYPE.VALUE.toLowerCase():
        return '2fr repeat(4, 1fr)';
      case QUIZZES_TYPE.VALUE.toLowerCase():
        return '1.5fr repeat(5, 1fr)';
      case USERS_TYPE.VALUE.toLowerCase():
        return 'repeat(2, 1fr) 3fr 0.5fr;';
      case ACTIVITIES_TYPE.VALUE.toLowerCase():
        return '1.5fr repeat(4, 1fr)';
      default:
        return '1.5fr repeat(3, 1fr)';
    }
  }, [currentChart]);

  const columnsMetadata: IColumnMetadata[] =
    ENTITIES_COLUMNS[currentChart.toUpperCase()][entitiesTable.currentEntity];

  const tableTabs = STATISTICS_ENTITIES[currentChart.toUpperCase()] ?? STATISTICS_ENTITIES.DEFAULT;

  const updateSelectedEntity = (value: string) => {
    actions && actions.onUpdateEntity(value);
  };
  const isEntitySelected = (entity: string) => {
    return entitiesTable.currentEntity === entity;
  };
  const showBadge = (index, value) => {
    if (
      currentChart === QUIZZES_TYPE.VALUE.toLowerCase() &&
      index === 4 &&
      value === '100%' &&
      !isMobile
    )
      return 'var(--positive-light)';
    return '';
  };
  const toggleOrder = () => {
    // TODO: impelement order
    const newOrder = order ? order * -1 : 1;
    setOrder(newOrder);
  };
  return (
    <TableWrapper loading={entitiesTable.loading}>
      <Spinner
        active={entitiesTable.loading}
        top={isMobile ? '170px' : '270px'}
        position="relative"
      />
      <Separator />
      {!hideHeader && (
        <OptionsSection lock={entitiesTable.loading}>
          <TableLabel>Activity</TableLabel>
          <EntitiesWrapper>
            {tableTabs?.map(({ LABEL: label, VALUE: value }) => {
              const isSelected = isEntitySelected(value);
              return (
                <EntityButton
                  active={isSelected}
                  key={`entity-${value}`}
                  onClick={() => updateSelectedEntity(value)}
                >
                  <span>{label.toUpperCase()}</span>
                </EntityButton>
              );
            })}
          </EntitiesWrapper>
        </OptionsSection>
      )}
      <TableContainer lock={entitiesTable.loading}>
        {entitiesTable?.items?.length != 0 ? (
          <Table className={section ? `${section}-table` : ''}>
            <Row
              className="entities-table__table-header"
              columns={activeUsersColumnsTableConfig}
              top={true}
            >
              {isMobile ? (
                <Top>{columnsMetadata[0].label.toUpperCase()}</Top>
              ) : (
                columnsMetadata?.map((column, index) => (
                  <Top key={index}>
                    {column.label}
                    {index !== 0 && column?.tooltip && (
                      <Tooltip
                        title={column?.tooltip}
                        placement="right"
                        overlayInnerStyle={{ textAlign: 'center' }}
                      >
                        <Icon
                          data-testid="info-inactive-icon"
                          style={iconStyles}
                          icon="Info"
                          fill={'var(--lilac)'}
                          size="smaller"
                        />
                      </Tooltip>
                    )}
                  </Top>
                ))
              )}
            </Row>
            {entitiesTable?.items?.map((item, index) => (
              <Row columns={activeUsersColumnsTableConfig} key={index}>
                {columnsMetadata?.map((column, index) => (
                  <Cell
                    className={column.name}
                    key={index}
                    badge={showBadge(index, column.value(item))}
                  >
                    <TableCellContent
                      key={index}
                      column={column}
                      index={index}
                      entity={entitiesTable.currentEntity}
                      item={item}
                      showValues={!isMobile}
                      card={false}
                    />
                  </Cell>
                ))}
                <Cell hidden={!isMobile} className="entities-table__table-mobile-details">
                  <RowMobile
                    data={item}
                    metadata={columnsMetadata}
                    entity={entitiesTable.currentEntity}
                  ></RowMobile>
                </Cell>
              </Row>
            ))}
          </Table>
        ) : (
          <EmptyTable>
            <div className="entities-table__empty_title">No data available</div>
            <div className="entities-table__empty_subtitle">
              No {entitiesTable.currentEntity} data available yet
            </div>
          </EmptyTable>
        )}
        {entitiesTable.items && entitiesTable.items.length != 0 && (
          <Pagination
            count={entitiesTable.count}
            page={entitiesTable.page}
            page_size={entitiesTable.pageSize}
            onPagesClick={(value, name) => actions.onChangePagination({ name: name, value })}
          />
        )}
      </TableContainer>
    </TableWrapper>
  );
};

export default EnititesTable;
