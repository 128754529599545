import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BannerContainer, BannerText, CloseButton, CloseIcon } from './styles';

const AnalyticsNotificationBanner = ({
  impersonated,
  onCloseNotification,
}: {
  impersonated: boolean;
  onCloseNotification: () => void;
}): JSX.Element => {
  const [showBanner, setShowBanner] = useState(true);

  const handleBannerClose = () => {
    setShowBanner(false);
    onCloseNotification();
  };

  if (!showBanner) {
    return null;
  }

  return (
    !isMobile && (
      <BannerContainer data-testid="banner-container" impersonated={impersonated}>
        <BannerText data-testid="banner-text">
          Analytics has been enhanced with an <b>activity chart exclusively for licensed users</b>,
          easy license management, and additional insights!
        </BannerText>
        <CloseButton data-testid="close-button" onClick={handleBannerClose}>
          <CloseIcon data-testid="close-button-icon" icon="Cancel2" size="medium" />
        </CloseButton>
      </BannerContainer>
    )
  );
};

export default AnalyticsNotificationBanner;
