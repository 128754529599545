import { IconTeam } from 'components/teamSelect/IconTeam';
import { TEAMS_ENTITY, USERS_ENTITY } from 'constants/analytics';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { ITableCellData } from 'contexts/analytics/types';
import { randomColor } from 'utils/colors';
import { LetterAvatar, UserImage } from './styles';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
const TableCellContent = ({
  index,
  item,
  column,
  entity,
  showValues,
  card,
}: ITableCellData): JSX.Element => {
  const Wrapper = useMemo(() => {
    const id = typeof column?.id === 'function' ? column.id(item) : undefined;
    return id && index === 0 && entity === USERS_ENTITY.VALUE
      ? ({ children }) => (
          <NavLink to={`/user/${id}`} onClick={(e) => e.stopPropagation()} className="users-link">
            {children}
          </NavLink>
        )
      : React.Fragment;
  }, [index, entity, column]);

  const getTypeTooltipText = (key) => {
    switch (key) {
      case 'course':
        return 'Courses';
      case 'learningpath':
        return 'Learning Path';
      default:
        return null;
    }
  };

  const renderValue = (item) => {
    const { content_type } = item;
    const value = column.value(item);
    if (!value) return null;
    const valueLimit = 100;
    if (content_type !== 'course' && content_type !== 'learningpath') return value;
    return (
      <Tooltip title={value} placement="bottom" overlayInnerStyle={{ textAlign: 'center' }}>
        {value.length > valueLimit ? value.slice(0, valueLimit) + '...' : value}
      </Tooltip>
    );
  };

  return (
    <Wrapper>
      <div className={'entities-table__table-body-row'}>
        {index === 0 &&
          (entity === USERS_ENTITY.VALUE ? (
            item?.user?.avatar && item?.user?.avatar !== DEFAULT_IMG_USERS ? (
              <UserImage src={item?.user?.avatar} />
            ) : (
              item && (
                <LetterAvatar color={randomColor(item?.user?.email)} card={card}>
                  {column.value(item)[0]}
                </LetterAvatar>
              )
            )
          ) : entity === TEAMS_ENTITY.VALUE ? (
            item?.team?.icon_name && item?.team?.icon_color ? (
              <IconTeam
                iconName={item?.team?.icon_name}
                color={item?.team?.icon_color}
                size={'small'}
                className="entities_icon_container"
              />
            ) : (
              <IconTeam
                iconName="group"
                color="blue"
                size={'small'}
                className={card && 'card-icon-container'}
              />
            )
          ) : (
            <></>
          ))}
        {(index === 0 || showValues) && item && (
          <div className={column.name + '__value'}>
            {column?.type && (
              <Tooltip
                title={getTypeTooltipText(column.type(item))}
                placement="bottom"
                overlayInnerStyle={{ textAlign: 'center' }}
              >
                <div className={`value-type-${column.type(item)}`} />
              </Tooltip>
            )}
            {renderValue(item)}
            {column.scndValue && item && (
              <div className={column.name + '__scnd'}>{column.scndValue(item)}</div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default TableCellContent;
