import { InputSelectOptions, InputTypeSelect, SelectDropdown } from './styles';
import Icon from 'components/icons';
import { Dropdown } from 'antd';
import { useState } from 'react';

interface InputSelectProps {
  value: string;
  onChange?: (selected: string | number) => void;
  options?: {
    label: string;
    value: string | number;
  }[];
  defaultOpen?: boolean;
  placeHolder?: string;
  error?: boolean;
}

const InputSelect: React.FC<InputSelectProps> = ({
  value,
  onChange,
  options,
  defaultOpen,
  placeHolder,
  error,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleSelection = (value: string | number) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <Dropdown
      overlay={
        <SelectDropdown>
          {options.map(({ label, value }) => {
            return (
              <InputSelectOptions key={value} onClick={() => handleSelection(value)}>
                {label}
              </InputSelectOptions>
            );
          })}
        </SelectDropdown>
      }
      trigger={['click']}
      visible={open}
      onVisibleChange={setOpen}
    >
      <InputTypeSelect error={error}>
        <span className="dropdown__input-value">
          {options.find((o) => o.value === value).label || placeHolder}
        </span>

        <Icon icon={'ChevronDown'} size={'smaller'} />
      </InputTypeSelect>
    </Dropdown>
  );
};

export default InputSelect;
