import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { AnalyticsContext } from 'contexts/analytics';
import SlideButtonsGroup from '../SlideButtonsGroup';
import ChartDataSelector from '../ChartDataSelector';
import { Container } from './styles';
import './statics.scss';
import ActiveUsersChart from '../ActiveUsersChart';
import { Spinner } from 'components/loadings';

const chartTypes = [
  { icon: 'LineChart', value: 'AreaChart' },
  { icon: 'BarChart', value: 'BarChart' },
];

interface Props {
  dataDisplay: { key: string; checked?: boolean }[];
  toggleCheckbox: (key: string) => void;
}

const AnalyticsActivitiesChart = ({ toggleCheckbox, dataDisplay }: Props): JSX.Element => {
  const [chartType, setChartType] = useState('AreaChart');
  const { activities, currentChart } = useContext(AnalyticsContext);

  const chartItems = activities?.items?.map(({ date, count }) => {
    const finished = activities.finished?.find((i: { date: string }) => i.date === date);
    const passed = activities.passed?.find((i: { date: string }) => i.date === date);
    const started = activities.started?.find((i: { date: string }) => i.date === date);
    const watched = activities.watched?.find((i: { date: string }) => i.date === date);
    const labs = activities.labs?.find((i: { date: string }) => i.date === date);
    const videos = activities.videos?.find((i: { date: string }) => i.date === date);
    const quizzes = activities.quizzes?.find((i: { date: string }) => i.date === date);
    const total = activities.total?.find((i: { date: string }) => i.date === date);
    const licensed = activities.licensed?.find((i: { date: string }) => i.date === date);
    const percentage = activities.percentage?.find((i: { date: string }) => i.date === date);
    return {
      date,
      count,
      finished: finished ? finished.count : 0,
      started: started ? started.count : 0,
      passed: passed ? passed.count : 0,
      watched: watched ? watched.count : 0,
      labs: labs ? labs.count : 0,
      videos: videos ? videos.count : 0,
      quizzes: quizzes ? quizzes.count : 0,
      total: total ? total.count : 0,
      licensed: licensed ? licensed.count : 0,
      percentage: percentage ? percentage.count : 0,
      currentChart,
    };
  });
  const updateChartType = (value: string) => {
    setChartType(value);
  };
  return (
    <>
      <Spinner
        active={activities.loading}
        top={isMobile ? '250px' : '50%'}
        left="50%"
        position="absolute"
      />
      <Container lock={activities.loading}>
        <div className="activities-chart__options-container">
          {dataDisplay.length > 1 && (
            <ChartDataSelector
              className="desktop-only"
              toggleCheckbox={toggleCheckbox}
              options={dataDisplay}
              chartType={chartType}
            />
          )}
          <SlideButtonsGroup action={updateChartType} options={chartTypes} current={chartType} />
        </div>
        <ActiveUsersChart
          items={chartItems}
          chartType={chartType}
          chartDataType={currentChart}
          Xaxis="date"
          Yaxis="count"
          dataDisplay={dataDisplay}
        />
        <div className="activities-chart__options-container activities-chart__options-container-mob">
          <ChartDataSelector
            className="mobile-only"
            toggleCheckbox={toggleCheckbox}
            options={dataDisplay}
            chartType={chartType}
          />
        </div>
      </Container>
    </>
  );
};

export default AnalyticsActivitiesChart;
