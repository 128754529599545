import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AnalyticsContext } from 'contexts/analytics';
import { SessionContext } from 'contexts/session';
import AnalyticsFilters from '../AnalyticsFilters';
import ActiveUsersStatsPanel from '../ActiveUsersStatsPanel';
import { Container, SectionCard, TitleContainer } from './styles';
import AnalyticsNotificationBanner from './AnalyticsNotificationBanner';
import { HEADER_HEIGHT, NOTICE_HEIGHT } from 'constants/settings';

const AnalyticsContent = ({
  onCloseNotification,
}: {
  onCloseNotification: () => void;
}): JSX.Element => {
  const [showingNotification, setShowingNotification] = useState(true);
  const { activities, counters, actions, currentChart, entitiesTable } =
    useContext(AnalyticsContext);

  const { isImpersonated } = useContext(SessionContext);

  // NOTICE_HEIGHT refers to the height of the notification of impersonation
  // HEADER_HEIGHT refers to the height of the layout header
  const basicHeight = isImpersonated ? HEADER_HEIGHT + NOTICE_HEIGHT : HEADER_HEIGHT;

  // 66px is the height of the TitleContainer plus margin-top 20px and 10px space between the title and the filters
  const titleHeight = 66;
  const [topPosition, setTopPosition] = useState(basicHeight + titleHeight);

  const handleBannerClose = () => {
    onCloseNotification();
    setShowingNotification(false);
  };
  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const newTopPosition = Math.max(basicHeight, basicHeight + titleHeight - scrollTop);
    setTopPosition(newTopPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Container>
        <AnalyticsNotificationBanner
          impersonated={isImpersonated}
          onCloseNotification={handleBannerClose}
        ></AnalyticsNotificationBanner>
        <TitleContainer
          fixedY={basicHeight + 'px'}
          className="analytics-teams-filters__title"
          showingNotification={showingNotification}
        >
          Analytics
        </TitleContainer>
        <SectionCard
          padding={'10px'}
          fixedY={topPosition + 'px'}
          showingNotification={showingNotification}
          style={{ borderBottom: '1px solid var(--bright-gray-lighter)' }}
        >
          <AnalyticsFilters />
        </SectionCard>
        <SectionCard padding={isMobile ? '0' : '30px'} rounded={!isMobile} shadow>
          <ActiveUsersStatsPanel
            activities={activities}
            actions={actions}
            counters={counters}
            tabSelected={currentChart}
            entitiesTable={entitiesTable}
          />
        </SectionCard>
      </Container>
    </>
  );
};

export default AnalyticsContent;
