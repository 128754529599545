import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { IFullCHartData } from 'contexts/analytics/types';
import { useMemo } from 'react';
import { durationFromSecs } from 'utils/helpers';
import { CHART_TYPES_LABELS } from 'constants/analytics';
interface Props {
  chartType: string;
  chartDataType?: string;
  items: IFullCHartData[];
  Xaxis: string;
  Yaxis: string;
  dataDisplay: {
    key: string;
    checked?: boolean;
    fixed?: boolean;
    fixedFor?: string[];
    disabled?: boolean;
  }[];
}

const ActiveUsersChart = (data: Props): JSX.Element => {
  const tooltipLabel = useMemo(() => {
    switch (data.chartDataType) {
      case 'videos_overall':
        return 'watched';
      default:
        return `total ${data?.chartDataType}`;
    }
  }, [data.chartDataType]);

  const customStyles = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.200691)',
    borderRadius: '4px',
    width: 'fit-content',
    padding: '8px 16px',
    background: '#57575C',
    color: '#ffffff',
  };

  const dotStyles = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '9px',
    border: '1px solid #E9E9ED',
  };

  const dataNumberText = (value) => {
    if (['videos_overall'].includes(data.chartDataType))
      return durationFromSecs(value * 60, 'hhmm') || '0m';
    return value;
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={customStyles}>
          {payload.map((entry, index) => {
            let action;
            const value = dataNumberText(entry.value);
            const chartDataType = data?.chartDataType.toUpperCase();
            const type =
              entry.value !== 1
                ? CHART_TYPES_LABELS[chartDataType]?.PLURAL
                : CHART_TYPES_LABELS[chartDataType]?.SINGULAR;
            if (chartDataType === 'USERS') {
              action = 'licensed users were active';
            } else {
              action = entry.name === 'count' ? `${tooltipLabel}` : `${type} ${entry.name}`;
            }
            return (
              <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ ...dotStyles, backgroundColor: entry.stroke || entry.fill }}></span>
                <p style={{ margin: 0 }}>{`${value} ${action} on ${label}`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {data.chartType === 'BarChart' ? (
        <ResponsiveContainer width={'90%'} height={'90%'}>
          <BarChart width={500} data={data.items}>
            <XAxis dataKey={data.Xaxis} />
            <YAxis dataKey={data.Yaxis} />
            {data.chartDataType && <Tooltip content={customTooltip} shared={false} />}
            <CartesianGrid strokeDasharray="3 3" />
            {data.dataDisplay.map((bar) => {
              if (bar.checked || bar.fixed || bar.fixedFor?.includes(data.chartType)) {
                return (
                  <Bar
                    key={bar.key}
                    dataKey={
                      ['overall', 'watched', 'licensed', 'percentage'].includes(bar.key)
                        ? 'count'
                        : bar.key
                    }
                    stackId={bar.key}
                    fill={COLOR_CHART_STATUSES[bar.key.toUpperCase()].fill}
                  />
                );
              }
            })}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={'90%'}>
          <AreaChart
            data={data.items}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis dataKey={data.Xaxis} />
            <YAxis dataKey={data.Yaxis} />
            {data.chartDataType && <Tooltip content={customTooltip} />}
            {data.dataDisplay.map((area) => {
              if (!area.disabled && (area.checked || area.fixed)) {
                return (
                  <Area
                    dot={{
                      fill: COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke,
                      strokeWidth: 7,
                      stroke: COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke,
                    }}
                    activeDot={{
                      cursor: 'pointer',
                      strokeWidth: 7,
                      stroke: COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke,
                    }}
                    key={area.key}
                    type="monotone"
                    dataKey={
                      ['overall', 'watched', 'licensed', 'percentage'].includes(area.key)
                        ? 'count'
                        : area.key
                    }
                    stroke={COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke}
                    fill={COLOR_CHART_STATUSES[area.key.toUpperCase()].fill}
                    strokeWidth={2}
                  />
                );
              }
            })}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ActiveUsersChart;
