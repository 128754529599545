import { Card, CardQuantity } from 'pages/Analytics/components/AnalyticsCounters/styles';
import { Container, CounterCardContainer, Label, Title } from './styles';
import { FC, useEffect, useState } from 'react';
import { durationOn, hhmmss } from 'utils/helpers';

import { ActivitiesCountersProps } from './types';
import { getAgregationAction } from 'contexts/analytics/actions';

const ActivitiesCounters: FC<ActivitiesCountersProps> = ({ activities, title }) => {
  const [counters, setCounters] = useState({});

  useEffect(() => {
    let isMounted = true;

    const processCountersData = async () => {
      activities.map(async ({ name, params }) => {
        try {
          const response = await getAgregationAction(params);
          if (isMounted) {
            setCounters((prevState) => {
              if (response instanceof Error || typeof response.results !== 'number') {
                return { ...prevState, [name]: '-' };
              } else {
                if (name === 'VIDEO_WATCHED_TIME') {
                  if (response.results) {
                    return {
                      ...prevState,
                      VIDEO_WATCHED_TIME: `${durationOn(
                        `${hhmmss(response.results * 60)}`,
                        response.results >= 600 ? 'hours' : 'minutes'
                      )}`,
                    };
                  } else {
                    return { ...prevState, VIDEO_WATCHED_TIME: '0h' };
                  }
                }

                return { ...prevState, [name]: response.results };
              }
            });
          }
        } catch (error) {
          if (isMounted) {
            setCounters((prevState) => ({
              ...prevState,
              [name]: '-',
            }));
          }
        }
      });
    };

    processCountersData();

    return () => {
      isMounted = false;
    };
  }, [activities]);

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {activities.map(({ name, label }) => (
        <Card key={name}>
          <CounterCardContainer>
            <CardQuantity>{counters?.[name] ?? '-'}</CardQuantity>
            <Label>{label}</Label>
          </CounterCardContainer>
        </Card>
      ))}
    </Container>
  );
};

export default ActivitiesCounters;
