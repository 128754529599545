import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MONTH_PERIOD, WEEK_PERIOD } from 'constants/filters';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import { getActiveUsersAction } from 'contexts/analytics/actions';
import {
  Title,
  TitleWrapper,
  Subtitle,
  Content,
} from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import ButtonIcon from 'components/buttonIcon';
import { Spinner } from 'components/loadings';
import { areaChartConfig, areaDataDisplay, DASHBOARD_ACTIVITIES_STATS } from './configs';
import ActivitiesCounters from 'components/stats/activitesCounters';
import { Col, Row } from 'antd';
import CustomAreaChart from 'components/stats/CustomAreaChart';

const DashboardUsersActiveStats = ({ user, account, isManager }): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    getActiveUsersData();
  }, []);

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const getActivitiesCountersData = () => {
    const activities = [];
    DASHBOARD_ACTIVITIES_STATS.map((ac) => {
      activities.push({
        name: ac.key,
        label: ac.label,
        params: {
          period: MONTH_PERIOD.VALUE,
          user_id: user.uaa_id,
          account_id: account?.id,
          actions: ac.actions,
          team_id: isManager ? user.team?.id : null,
          external_action: ac.external_actions,
        },
      });
    });
    return activities;
  };

  const getActiveUsersData = async () => {
    const params = {
      period: WEEK_PERIOD.VALUE,
      user_id: user.uaa_id,
      date_to: undefined,
      date_from: undefined,
      account_id: account?.id,
      team_id: isManager ? user.team?.id : null,
      actions: undefined,
    };
    getActiveUsersAction(params)
      .then((res) => {
        setFetching(false);
        setActiveUsers(res instanceof Error ? [] : res.results);
      })
      .catch(() => {
        setFetching(false);
      });
  };

  return (
    <SectionCard padding={isMobile ? '0' : '30px'} rounded dashboard shadow>
      <>
        <header className="active-users-collapse__header" onClick={() => toggleCollapse()}>
          <TitleWrapper className="active-users-collapse__title-wrapper">
            <Title>Users with activity</Title>

            <ButtonIcon $terciary size="small" icon={collapsed ? 'ChevronUp' : 'ChevronDown'} />
          </TitleWrapper>
          <Subtitle>
            Number of users who have performed at least one individual activity per day within the
            last month
          </Subtitle>
        </header>
        {collapsed && (
          <>
            <Spinner active={fetching} top={isMobile ? '250px' : '200px'} position="relative" />
            <Content fetching={fetching} className="mt-5">
              <Row>
                <Col lg={4} md={24} sm={24} xs={24}>
                  <ActivitiesCounters activities={getActivitiesCountersData()} />
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  <CustomAreaChart
                    areaData={activeUsers}
                    chartConfig={areaChartConfig}
                    dataDisplay={areaDataDisplay}
                  />
                </Col>
              </Row>
            </Content>
          </>
        )}
      </>
    </SectionCard>
  );
};

export default DashboardUsersActiveStats;
