import { Tooltip } from 'antd';
import { Card, CardQuantity, Container, Label, SpinnerContainer } from './styles';
import Icon from 'components/icons';
import { Spinner } from 'components/loadings';

interface Props {
  quantity: number | string;
  label: string;
  color: string;
  tooltipLabel?: string;
  displayDot?: boolean;
  fetching?: boolean;
  percentage?: boolean;
  style?: React.CSSProperties;
}

const CounterCard = ({
  quantity,
  label,
  color,
  tooltipLabel,
  displayDot,
  fetching,
  percentage,
  style,
}: Props): JSX.Element => {
  const iconStyles = {
    position: 'absolute',
    top: '10.5px',
    right: '10.5px',
  } as React.CSSProperties;

  return (
    <Card style={{ ...style, position: 'relative' }}>
      {fetching ? (
        <SpinnerContainer>
          <Spinner active={true} size="25" />
        </SpinnerContainer>
      ) : (
        <>
          {tooltipLabel && (
            <Tooltip
              title={tooltipLabel}
              placement="right"
              overlayInnerStyle={{ textAlign: 'center' }}
            >
              <Icon
                data-testid="info-inactive-icon"
                style={iconStyles}
                icon="Info"
                fill={'var(--lilac)'}
                size="smaller"
              />
            </Tooltip>
          )}
          <Container>
            <CardQuantity>
              {quantity || 0}
              {percentage && '%'}
            </CardQuantity>
            <Label color={displayDot ? color : null}>{label}</Label>
          </Container>
        </>
      )}
    </Card>
  );
};

export default CounterCard;
