import styled from 'styled-components';
import CounterCard from './CounterCard';
import { useContext } from 'react';
import { AnalyticsContext } from 'contexts/analytics';

import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { screenMd, screenSm } from 'constants/styles/breakpoints';
import { durationFromSecs } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  background-color: #f2f2f7;
  padding: 10px;
  ${screenSm} {
    flex-direction: column;
    width: 172px;
    padding: 15px;
  }
  ${screenMd} {
    width: 172px;
  }
`;

interface ICounterItem {
  key: string;
  counterLabel?: string;
  counterTooltipLabel?: string;
  disabled?: boolean;
  percentageKey?: string;
}

const getValue = (key: string, value: number) => {
  switch (key) {
    case 'percentage':
      return value ? `${value}%` : '0%';
    case 'watched':
      return durationFromSecs(value * 60, 'hhmm').replace(' ', '');
    default:
      return value;
  }
};

const AnalyticsCounters = ({
  displayCounters,
}: {
  displayCounters: ICounterItem[];
}): JSX.Element => {
  const { counters, currentChart, fetching } = useContext(AnalyticsContext);

  const getQuantity = (key, percentageKey?) => {
    const value = counters[currentChart][key];
    if (percentageKey) {
      const percentage = counters[currentChart][percentageKey];
      return percentage ? Math.floor((value / percentage) * 100) : 0;
    }
    return getValue(key, value);
  };

  const getLabel = (key, isPercentage, label?) => {
    if (isPercentage) {
      return `${getQuantity(key)} ${label || key}`;
    }
    return label || key;
  };

  return (
    <Container>
      {displayCounters.map(
        ({ key, counterLabel, counterTooltipLabel, disabled, percentageKey }) => {
          return (
            <>
              <CounterCard
                fetching={fetching}
                key={key}
                quantity={getQuantity(key, percentageKey)}
                label={getLabel(key, !!percentageKey, counterLabel)}
                tooltipLabel={counterTooltipLabel}
                displayDot={!disabled}
                percentage={!!percentageKey}
                color={
                  disabled
                    ? null
                    : COLOR_CHART_STATUSES[key.toUpperCase()].counter ??
                      COLOR_CHART_STATUSES[key.toUpperCase()].stroke
                }
                style={!counterLabel ? { textTransform: 'capitalize' } : {}}
              />
            </>
          );
        }
      )}
    </Container>
  );
};

export default AnalyticsCounters;
