import './statics.scss';
import Checkbox from 'components/checkbox';
import { capitalize } from 'utils/helpers';
import { Tooltip } from 'antd';
import Icon from 'components/icons';
interface FCProps {
  options: {
    key: string;
    label?: string;
    checked?: boolean;
    tooltipLabel?: string;
    disabled?: boolean;
    fixedFor?: string[];
  }[];
  toggleCheckbox: (value: string) => void;
  className: string;
  chartType?: string;
}

const ChartDataSelector = ({
  options,
  toggleCheckbox,
  className,
  chartType,
}: FCProps): JSX.Element => {
  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  } as React.CSSProperties;

  return (
    <div className={`chart-data-selector ${className}`}>
      {options.map(({ key, label, checked, tooltipLabel, disabled, fixedFor }) => {
        return (
          !disabled && (
            <div key={key} style={containerStyles}>
              <Checkbox
                key={key}
                className={`chart-data-selector__option-${key} ${
                  fixedFor?.includes(chartType) ? 'hide' : ''
                }`}
                checked={checked}
                onChange={() => toggleCheckbox(key)}
                controlled
                label={label ? label : capitalize(key)}
              />
              {tooltipLabel && (
                <Tooltip
                  title={tooltipLabel}
                  placement="right"
                  overlayInnerStyle={{ textAlign: 'center' }}
                >
                  <Icon
                    data-testid="info-inactive-icon"
                    icon="Info"
                    fill={'var(--lilac)'}
                    size="smaller"
                  />
                </Tooltip>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default ChartDataSelector;
