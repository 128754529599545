/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleErrors } from 'contexts/helpers';
import {
  getActiveUsers,
  getAgregation,
  getCoursesTable,
  getPreferredContent,
  getSeries,
  getTeamsTable,
  getUsersQuizzTable,
  getUsersTable,
  getUsersWithActivityActivitiesTable,
  getUsersWithActivityPercentage,
  getUsersWithActivityUsersTable,
} from 'services/reporting';
import {
  AnalyticsUserActivePercentageParams,
  AnalyticsUserTableParams,
  PreferredContentParams,
  UsersWithActivityUsersTableParams,
} from './types';

export const getUsersTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

// export const getUsersTableQuizzAction = async (
//   params: AnalyticsUserTableParams
// ): Promise<{ results: any } | Error> => {
export const getUsersTableQuizzAction = (params: AnalyticsUserTableParams): any => {
  // try {
  //   const { data } = await getUsersQuizzTable(params);
  //   return {
  //     results: data,
  //   };
  // } catch (error) {
  //   return handleErrors(error);
  // }
  const results = {
    results: {
      data: [
        {
          started_quizzes: 2,
          finished_quizzes: 2,
          passed_quizzes: 1,
          passed_quizzes_percentage: 0.5,
          average_score: 60.5,
          user: {
            avatar: 'https://inedev-profiles.s3.amazonaws.com/defaults/default.png',
            business_id: 1,
            email: 'email@a.com',
            first_name: 'Name',
            id: 'user_uaa_id',
            last_name: 'Lastname',
          },
        },
        {
          started_quizzes: 5,
          finished_quizzes: 6,
          passed_quizzes: 2,
          passed_quizzes_percentage: 1.5,
          average_score: 20,
          user: {
            avatar: 'https://inedev-profiles.s3.amazonaws.com/defaults/default.png',
            business_id: 1,
            email: 'email@a.com',
            first_name: 'Name',
            id: 'user_uaa_id',
            last_name: 'Lastname',
          },
        },
        {
          started_quizzes: 15,
          finished_quizzes: 26,
          passed_quizzes: 42,
          passed_quizzes_percentage: 100,
          average_score: 60,
          user: {
            avatar: 'https://inedev-profiles.s3.amazonaws.com/defaults/default.png',
            business_id: 1,
            email: 'email@a.com',
            first_name: 'Name',
            id: 'user_uaa_id',
            last_name: 'Lastname',
          },
        },
      ],
      pagination: {
        page: 1,
        page_size: 9,
        total: 3,
      },
    },
  };
  return results;
};

export const getUsersTableActivitiesAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersWithActivityActivitiesTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getTeamsTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getCoursesTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getCoursesTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getSeriesByTypeAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getSeries(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { date: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getAgregationAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getAgregation(params);
    return {
      results: data['result'],
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getActiveUsersAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getActiveUsers(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { date: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getPreferredContentAction = async (
  params: PreferredContentParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getPreferredContent(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { name: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersWithActivityUsersTableAction = async (
  params: UsersWithActivityUsersTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersWithActivityUsersTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersWithActivityPercentageAction = async (
  params: AnalyticsUserActivePercentageParams
): Promise<{ results: any } | Error> => {
  try {
    const response = await getUsersWithActivityPercentage(params);
    const { data } = response;
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
